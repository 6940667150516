// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Ozan\\Backup\\alaska\\alaska-teknoloji\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("C:\\Ozan\\Backup\\alaska\\alaska-teknoloji\\src\\pages\\company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("C:\\Ozan\\Backup\\alaska\\alaska-teknoloji\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\Ozan\\Backup\\alaska\\alaska-teknoloji\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}


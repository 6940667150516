import React, { Component } from "react";
import { navigate } from "gatsby";
import "../styles/navbar.scss";

export default class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            homePages: ["home", "products", "services"],
            page: null,
            contact: null
        }
    }

    scrollOps = () => {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const { homePages, page } = this.state;
        const isHome = homePages.indexOf(page) > -1;

        let height = windowHeight * 0.5;

        if(!isHome) { height = windowHeight * 0.1; }

        if(scrollPosition >= height) {
            document.documentElement.dataset.scroll = 1;
        } else {
            document.documentElement.dataset.scroll = 0;
        }

        if(isHome) {
            const offset = height;
            const posServices = document.getElementById("services").offsetTop - offset;
            const posProducts = document.getElementById("promote").offsetTop - offset;
            
            if(scrollPosition >= posServices) {
                document.documentElement.dataset.services = 1;
            } else {
                document.documentElement.dataset.services = 0;
            }

            if(scrollPosition >= posProducts) {
                document.documentElement.dataset.products = 1;
            } else {
                document.documentElement.dataset.products = 0;
            }

            if(scrollPosition >= posServices) {
                this.setState({ page: "services" });
            } else if(scrollPosition >= posProducts) {
                this.setState({ page: "products" });
            } else {
                this.setState({ page: "home" });
            }
        }
    }

    resizeOps = () => {
        this.scrollOps();

        const vh = window.innerHeight * 0.01;
        const nh = document.getElementById("conbar").offsetHeight + document.getElementById("navbar").offsetHeight;

        document.documentElement.style.setProperty("--vh", vh + "px");
        document.documentElement.style.setProperty("--nh", nh + "px");
    }

    componentDidMount() {
        fetch("/data/contact.json").then((r) => {
            r.json().then((r) => this.setState({ contact: r.data.firm }));
        });
        
        // Path Ops

        let pathname = window.location.pathname;

        pathname = pathname.replace(/\//g, "");

        if(pathname === "") {
            this.setState({ page: "home" });
        } else {
            this.setState({ page: pathname });
        }

        // Mobile Navigation

        const navButtons = document.querySelectorAll("#navStatic div.button");
        const navMobile = document.getElementById("navMobile");

        for(let i = 0; i < navButtons.length; i++) {
            let div = document.createElement("div");

            div.className = "button";
            div.innerHTML = navButtons[i].innerHTML;

            div.onclick = () => {
                document.documentElement.dataset.navMobile = 0;
                navButtons[i].click();
            }

            navMobile.appendChild(div);
        }

        // Listeners

        this.resizeOps();
        window.addEventListener("scroll", this.scrollOps, { passive: true });
        window.addEventListener("resize", this.resizeOps, { passive: true });
    }

    componentDidUpdate(prevProps, prevState) {
        // Title Ops

        if(prevState.page !== this.state.page) {
            const { page } = this.state;
            const siteName = "Alaska Teknoloji"
            const divider = " | ";

            if(page === "company") {
                document.title = siteName + divider + "Kurumsal";
            } else if(page === "contact") {
                document.title = siteName + divider + "İletişim";
            } else {
                document.title = siteName;
            }
        }

        // Hash Ops
        
        let hash = window.location.hash;

        hash = hash.replace("#", "");

        if(hash !== "") {
            setTimeout(() => {
                const offset = document.getElementById("navbar").offsetHeight + 45;

                let top = 0;

                if(hash === "products") {
                    top = document.getElementById("promote").offsetTop;
                } else {
                    top = document.getElementById(hash).offsetTop;
                }

                top = top - offset;

                window.scrollTo({ top, behavior: "auto" });
            }, 25);

            setTimeout(() => window.history.replaceState(null, null, "/"), 100);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollOps);
        window.removeEventListener("resize", this.resizeOps);
    }

    navigate = (id) => {
        const { homePages, page } = this.state;

        if(homePages.indexOf(page) > -1 && homePages.indexOf(id) > -1) {
            const offset = document.getElementById("navbar").offsetHeight + 45;

            let destination = 0;

            if(id === "products") { destination = document.getElementById("promote").offsetTop; }
            if(id === "services") { destination = document.getElementById("services").offsetTop; }

            window.scrollTo({ top: destination === 0 ? destination : destination - offset, behavior: "smooth" });
        } else {
            const duration = 400 * 0.9;
            
            if(homePages.indexOf(id) > -1) {
                document.documentElement.dataset.routing = 1;

                if(id === "home") {
                    this.setState({ page: "home" });
                    
                    setTimeout(() => navigate("/"), duration);
                } else {
                    this.setState({ page: id });
                    
                    setTimeout(() => navigate("/#" + id), duration);
                }
            } else {
                if(id !== page) {
                    this.setState({ page: id });

                    document.documentElement.dataset.routing = 1;

                    setTimeout(() => navigate("/" + id), duration);
                } else {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
            }
        }
    }

    navButton = (id, name) => (
        <div
            id={ "nav-" + id }
            className={ this.state.page !== id ? "button" : "button selected" }
            onClick={ () => this.navigate(id) }
        >
            { name }
        </div>
    )
    
    render() {
        return(
            <div>
                <div className="navbarContainer">
                    <div id="conbar" className="conbar">
                        <div>
                            { this.state.contact ? this.state.contact[2].name + ": " + this.state.contact[2].value : null }
                            { " | " }
                            { this.state.contact ? this.state.contact[1].name + ": " + this.state.contact[1].value : null }
                        </div>
                    </div>

                    <div id="navbar" className="navbar">
                        <div className="left">
                            <img className="logo" src="/assets/logo.png" alt="Alaska Teknoloji" onClick={ () => this.navigate("home") }></img>
                        </div>

                        <div className="right">
                            <div id="navStatic" className="navStatic">
                                { this.navButton("home", "Anasayfa") }
                                { this.navButton("products", "Ürünler") }
                                { this.navButton("services", "Hizmetler") }
                                { this.navButton("company", "Kurumsal") }
                                { this.navButton("contact", "İletişim") }
                            </div>

                            <div className="burger" onClick={ () => document.documentElement.dataset.navMobile = "1" } />
                        </div>
                    </div>

                    <div id="navMobile" className="navMobile">
                        <div className="close" onClick={ () => document.documentElement.dataset.navMobile = "0" } />
                    </div>
                </div>
            </div>
        )
    }
}